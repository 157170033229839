import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons';

class StrokeCount extends Component {

    addStroke = () => {
        this.props.countButtonInfo.strokes++;
        this.props.strokeCountChange(this.props.countButtonInfo.strokes);
    }

    reduceStroke = () => {
        this.props.countButtonInfo.strokes--;
        this.props.strokeCountChange(this.props.countButtonInfo.strokes);
    }

    render() {

        return (
            <div className='score-buttons'>

                <div className='row score-button-title'>{this.props.countButtonInfo.type}</div>

                <div className='row score-button-strokes-taken'>
                    {this.props.countButtonInfo.strokes}
                </div>

                <div className='row'>
                    <div className='column score-button-icons'>
                        <FontAwesomeIcon icon={faCircleMinus} className='score-down' onClick={this.reduceStroke} />

                    </div>

                    <div className='column score-button-icons'>
                        <FontAwesomeIcon icon={faCirclePlus} className='score-up' onClick={this.addStroke} />
                    </div>
                </div>
            </div>
        );
    }
}
export default StrokeCount;