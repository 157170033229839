import logo from './images/golf-unsplash.jpg';
import './App.css';
import './scoring.css';
import { Component } from 'react';
import ScoreCard from './components/ScoreCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faGrinTongueSquint } from '@fortawesome/free-solid-svg-icons';
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import StrokeCount from './components/StrokeCount';

class App extends Component {

  state = {
    currentHoleStrokes: 0,
    currentHolePutts: 0,
    scoreCard: [],
    displayHole: 1,
  }

  storedCard = [];
  currentHoleIndex = this.state.displayHole - 1;
  numPrevHoles = 0;


  componentDidMount() {

    if (localStorage.getItem("scoresRecorded")) {
      let returnedCard = localStorage.getItem("scoresRecorded");
      this.storedCard = JSON.parse(returnedCard);
      this.currentHoleIndex = this.storedCard.length;
      this.updateState(this.numPrevHoles);
    } else {
      this.storedCard = [];
    }
  }

  calculateScore = () => {
    if (this.state.scoreCard.length === 0) {
      return 0;
    } else {
      return this.state.scoreCard.reduce((score, hole) => score + hole.strokes, 0);
    };
  }

  updateStrokes = (strokes) => {
    this.setState({
      currentHoleStrokes: strokes
    })
  }

  updatePutts = (strokes) => {
    this.setState({
      currentHolePutts: strokes
    })
  }

  previousHole = () => {
    this.numPrevHoles++;
    this.updateState(this.numPrevHoles);
  }

  saveHole = (e) => {

    e.preventDefault();
    
    let addHole = {
      "strokes": this.state.currentHoleStrokes + this.state.currentHolePutts,
      "putts": this.state.currentHolePutts
    }

    if (this.numPrevHoles > 0) {
      this.storedCard[this.currentHoleIndex - this.numPrevHoles] = addHole;
      this.numPrevHoles--;
    } else {
      this.storedCard.push(addHole);
      this.currentHoleIndex++;
    }

    let updatedCard = JSON.stringify(this.storedCard);
    localStorage.setItem("scoresRecorded", updatedCard);

    this.updateState(this.numPrevHoles);
  }

  resetRound = (e) => {
    e.preventDefault();
    this.storedCard.splice(0, this.storedCard.length);
    localStorage.removeItem("scoresRecorded");
    this.currentHoleIndex = 0;
    this.numPrevHoles = 0;
    this.updateState(this.numPrevHoles);
  }

  updateState = (prevHole) => {
    if (prevHole > 0) {
      this.setState({
        currentHoleStrokes: this.storedCard[this.currentHoleIndex - prevHole].strokes - this.storedCard[this.currentHoleIndex - prevHole].putts,
        currentHolePutts: this.storedCard[this.currentHoleIndex - prevHole].putts,
        scoreCard: this.storedCard,
        displayHole: (this.currentHoleIndex - prevHole) + 1
      })
    } else {
      this.setState({
        currentHoleStrokes: 0,
        currentHolePutts: 0,
        scoreCard: this.storedCard,
        displayHole: this.currentHoleIndex + 1
      })
    }
  }

  render() {

    return (
      <div className="App row">

        <div className="column left">
          <form onSubmit={this.resetRound}>
            <button>Reset Round</button>
          </form>
          <div className='score-card hole score-total-heading'>Score</div>
          <div className='score-card score-total'>{this.calculateScore()}</div>
        </div>

        <div className='column middle'>
          <img src={logo} className="App-logo" alt="golf ball on grass by Great Pictures on Unsplash -- Golf Course Lindau Bodensee, Germany" />

          <div className='displayHole'>#{this.state.displayHole}</div>
          <StrokeCount key={1} countButtonInfo={
            {
              "type": "Strokes",
              "strokes": this.state.currentHoleStrokes,
            }
          }
            strokeCountChange={this.updateStrokes}
          />

          <StrokeCount key={2} countButtonInfo={
            {
              "type": "Putts",
              "strokes": this.state.currentHolePutts
            }
          }
            strokeCountChange={this.updatePutts}
          />
          <button className={(this.state.displayHole === 1) ? "hide" : "buttonNavPrev"} onClick={this.previousHole}>Prev</button>

          <form onSubmit={this.saveHole}>
            <button className={(this.state.displayHole === 1) ? "" : "buttonNavNext"}>Next</button>
          </form>
        </div>

        <div className='column right'>
          <ScoreCard scores={this.state.scoreCard} newRound={this.resetRound} />
        </div>

      </div>
    );

  }
}

export default App;
