import { Component } from 'react';
import ScoreCardElement from './ScoreCardElement';

class ScoreCard extends Component {

    render() {

        return (
            <div className="row">

                <div className='column column-score'>
                    <div className='score-card hole score-card-heading score-card-heading'>Hole</div>
                    {this.props.scores.map((hole, index) => <ScoreCardElement key={index} type={"hole"} content={index + 1} />)}
                </div>

                <div className='column column-score'>
                    <div className='score-card score score-card-heading score-card-heading'>Score /Putts</div>
                    {this.props.scores.map((hole, index) => <ScoreCardElement key={index}
                        type={"score"} content={`${hole.strokes} /${hole.putts}`} />)}
                </div>

            </div >

        );

    }
}
export default ScoreCard;